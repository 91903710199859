const { objectChangeKeyCase } = Window

const currentDate = () => new Date().toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })

const subDate = (date1, date2) => (new Date(date1) - new Date(date2)) / (1000 * 60 * 60 * 24)

const addDay = (date, days = 0) => {
  const dateFormatParams = ['sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' }]
  if (!days) return new Date(date).toLocaleString(...dateFormatParams)
  const result = new Date(new Date(date).setHours(0, 0, 0))
  result.setDate(result.getDate() + days)
  return new Date(result).toLocaleString(...dateFormatParams)
}
const snakeToCamelCase = item => item.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

const computeDaysAfterConfirmedAndStart = (days = 0, dateConfirmed = currentDate(), dateStart = currentDate()) => subDate(dateStart, dateConfirmed) + Number(days)

const computeDateStart = (actualDays, { dayStart }, dateStart) => {
  if (actualDays >= dayStart) return dateStart
  return addDay(dateStart, dayStart - actualDays)
}
const computeDateEnd = (actualDays, { dayStart, dayEnd }, dateStart) => {
  const daysDiff = dayEnd - (actualDays > dayStart ? actualDays : dayStart)
  return addDay(dateStart, daysDiff)
}

const getFeedingPrograms = (actualDays, item) => item.feedingPrograms.filter(subItem => subItem.day_end >= actualDays)

const getFeedingProgram = (items, stockId) => items.find(item => item.stock_id === stockId)

const getForecastAttributes = (actualDays, item, date) => {
  const { stockId = null } = item
  const type = item.id
  if (stockId) {
    const feedingPrograms = getFeedingPrograms(actualDays, item)
    const stock = objectChangeKeyCase(getFeedingProgram(feedingPrograms, stockId))
    const dateStart = item.stockId ? computeDateStart(actualDays, stock, date) : null
    const dateEnd = item.stockId ? computeDateEnd(actualDays, stock, dateStart) : null
    const { dayStart, dayEnd } = stock
    return { ...item, feedingPrograms, type, dateStart, dateEnd, dayStart, dayEnd }
  }
  const dateStart = null
  const dateEnd = null
  const feedingPrograms = getFeedingPrograms(actualDays, item)
  return { ...item, type, feedingPrograms, dateStart, dateEnd }
}

const modifyFeedingProgramAttributes = (actualDays, dateStart) => item => {
  item = objectChangeKeyCase(item)
  return getForecastAttributes(actualDays, item, dateStart)
}

export {
  computeDaysAfterConfirmedAndStart,
  modifyFeedingProgramAttributes,
  computeDateStart,
}