import repository from './repository'

const resource = 'customer-forecasts'

export default {
  customer (customer) {
    return repository.get(`${resource}/${customer}/customer`)
  },
  store (data) {
    return repository.post(resource, data)
  },
  show (id, params) {
    return repository.get(`${resource}/${id}/show`, { params })
  },
  edit (id, params) {
    return repository.get(`${resource}/${id}/edit`, { params })
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
}
