<template lang="pug">
  v-container
    base-snackbar(
      :errors.sync="cxForecastPostErrors"
    )
    base-snackbar(
      :errors.sync="stockCategoryGetErrors"
    )
    base-snackbar(
      :errors.sync="fdPrgmCategoryGetErrors"
    )
    v-overlay(
      absolute
      color="primary"
      :value="populatingInputs"
    )
      v-progress-circular(
        color="white"
        indeterminate
        size="60"
      )
    v-toolbar(
      :color="editMode ? 'yellow darken-3' : 'primary'"
      dark
      dense
    )
      span {{ editMode ? 'Edit' : 'Create' }} Forecast - {{ customerData.company }}
      v-spacer
      v-btn(
        text
        small
        @click="populateInputs(defaultFieldValues)"
      )
        v-icon mdi-pencil
    v-row
      v-col(
        lg="3"
        cols="12"
      )
        v-row
          v-col(cols="12")
            v-autocomplete(
              label="Stock Category"
              :items="stockCategoryList"
              item-value="id"
              item-text="name"
              return-object
              :loading="stockCategoryGetting"
              v-model="stockCategory"
              :error-messages="cxForecastPostErrors.stock_category_id"
            )
              template(v-slot:append v-if="stockCategory.sow_pig_cycle_preference")
                base-tooltip(
                  :color="editMode ? 'yellow darken-3' : 'primary'"
                  text
                  x-small
                  dark
                  :tooltip="editMode ? 'Edit Sow Pig' : 'Attach Sow Pig'"
                  @click="openDialogAttachSowPig"
                )
                  v-icon mdi-book-sync
          v-col(cols="12")
            v-text-field(
              v-if="!stockCategory.sow_pig_cycle_preference"
              label="Quantity"
              type="number"
              v-model="quantity"
              :error-messages="cxForecastPostErrors.quantity"
            )
            v-text-field(
              v-else
              label="Quantity"
              type="number"
              disabled
              :value="1"
              :error-messages="cxForecastPostErrors.quantity"
            )
          v-col(cols="12")
            v-text-field(
              label="Days"
              type="number"
              v-model="days"
              :error-messages="cxForecastPostErrors.days"
            )
          v-col(cols="12")
            v-text-field(
              label="Date Confirmed"
              type="date"
              v-model="dateConfirmed"
              :error-messages="cxForecastPostErrors.date_confirmed"
            )
          v-col(cols="12")
            v-text-field(
              label="Date Start"
              type="date"
              v-model="dateStart"
              :error-messages="cxForecastPostErrors.date_start"
            )
          v-col(cols="12")
            v-checkbox(
              label="Closed"
              v-model="closed"
              :error-messages="cxForecastPostErrors.closed"
            )
          v-col(cols="6")
            v-btn(
              block
              color="primary"
              @click="sendRequestToCustomerForecast"
              :loading="cxForecastPosting"
            )
              span Save
          v-col(cols="6")
            v-btn(
              block
              color="error"
              @click="$router.push({ name: 'customers.forecasts.table', params: { customerId: customerData.id } })"
            )
              span Cancel
      v-col(lg="9" cols="12")
        v-card.mt-2(
          elevation="0"
          min-height="50vh"
        )
          v-container
            v-overlay(
              color="purple darken-3"
              :value="fdPrgmCategoryGetting && !populatingInputs"
              absolute
            )
              v-progress-circular(
                indeterminate
                size="64"
              )
            div(
              v-for="(item, index) in forecasts"
              :key="index"
            )
              v-row
                v-col(cols="3")
                  v-text-field(
                    label="Type"
                    :value="item.description"
                    readonly
                    :error-messages="cxForecastPostErrors[`types.${index}.type`]"
                  )
                v-col(cols="3")
                  v-autocomplete(
                    label="Stock Code"
                    :items="item.feedingPrograms"
                    v-model="item.stockId"
                    item-value="stock_id"
                    @change="feedingProgramChangeEvent(item, index)"
                    :error-messages="cxForecastPostErrors[`types.${index}.stock_id`]"
                  )
                    template(v-slot:selection="{ item }")
                      span.stock-description.blue-grey--text.mx-1 {{ item.stock_id }}
                    template(v-slot:item="{ item }")
                      span.primary--text {{ item.stock_id }}
                      span.blue-grey--text.mx-1.text-caption {{ item.stock.description }}
                v-col(cols="3")
                  v-text-field(
                    label="Date Start"
                    type="date"
                    readonly
                    v-model="item.dateStart"
                    :error-messages="cxForecastPostErrors[`types.${index}.date_start`]"
                  )
                v-col(cols="3")
                  v-text-field(
                    label="Date End"
                    type="date"
                    readonly
                    v-model="item.dateEnd"
                  )
              v-divider
    v-dialog(
      v-model="sowPigDialog"
      max-width="800"
      eager
    )
      v-card
        v-toolbar(
          :color="editMode ? 'yellow darken-3' : 'primary'"
          dense
          elevation="0"
          dark
        )
          span {{ editMode ? 'Edit Sow Pig' : 'Attach Sow Pig' }}
        v-container
          sow-pig-form(
            v-model="sowPigData"
            :errors="sowPigErrors"
          )
          div(v-if="editMode")
            v-btn(
              color="yellow darken-3"
              dark
              dense
              small
              :loading="sowPigPutting"
              @click="sendRequestSowPigAndForecast"
            )
              span Save
            v-btn.ml-2(
              color="success"
              dark
              dense
              small
              @click="revertSowPig"
            )
              span Restore
</template>
<style lang="scss" scope>
  span.stock-description {
    font-size: 0.8em;
  }
</style>
<script>
import sowPigRepository from '@/repositories/sow-pig.repository'
import customerForecastRespository from '@/repositories/customer-forecast.respository'
import stockCategoryRepository from '@/repositories/stock-category.repository'
import feedingProgramCategoryRepository from '@/repositories/feeding-program-category.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import { modifyFeedingProgramAttributes, computeDaysAfterConfirmedAndStart, computeDateStart } from '@/views/admin/customers/forecasts/modules/forecast-methods.module'
import searchDelay from '@/libs/searchDelay.extra'

const { objectChangeKeyCase, getCurrentDate } = Window

const populatingInputs = searchDelay(1000)

const [feedingProgramCategoryGetVars, feedingProgramCategoryGetVarNames] = requestVars({ identifier: 'fd-prgm-category' })
const [stockCategoryGetVars, stockCategoryGetVarNames] = requestVars({ identifier: 'stock-category' })
const [customerForecastGetVars, customerForecastGetVarNames] = requestVars({ identifier: 'cx-forecast', data: 'defaultFieldValues' })
const [customerForecastUploadVars, customerForecastUploadVarNames] = requestVars({ identifier: 'cx-forecast', request: 'post', hasData: false })
const [sowPigUpdateVars, sowPigUpdateVarNames] = requestVars({ identifier: 'sow-pig', hasData: false, request: 'put' })

const currentDate = () => new Date().toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit' })

const customerForecastGetRequestHandler = new VueRequestHandler(null, customerForecastGetVarNames)
const customerForecastUploadRequestHandler = new VueRequestHandler(null, customerForecastUploadVarNames)
const stockCategoriesGetRequestHandler = new VueRequestHandler(null, stockCategoryGetVarNames)
const feedingProgramCategoriesGetRequestHandler = new VueRequestHandler(null, feedingProgramCategoryGetVarNames)
const sowPigUpdateRequestHandler = new VueRequestHandler(null, sowPigUpdateVarNames)

let lastSaveSowPigData = []

const inputVars = () => ({
  stockCategory: {},
  quantity: null,
  days: null,
  dateConfirmed: currentDate(),
  closed: false,
  dateStart: currentDate(),
})

const createSowPigInputs = () => ({
  sowPigDialog: false,
  sowPigData: {},
})

const getDefaultValueParams = () => ({
  has: ['types'].join(','),
  with: [
    'types.feedingProgramCategory.feedingPrograms.stock',
    'stockCategory.latestStock',
    'stockCategory.sowPigCyclePreference',
    'sowPig',
  ].join(','),
})

export default {
  name: 'CreateCustomerForecast',
  components: {
    sowPigForm: () => import('./sow-pigs/Create'),
  },
  props: {
    customerData: {
      type: Object,
      default: () => ({}),
    },
  },
  created () {
    this.getStockCategories()
    this.getDefaultFieldValues()
    this.initializeSowPigData()
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...feedingProgramCategoryGetVars,
      ...stockCategoryGetVars,
      ...customerForecastUploadVars,
      ...customerForecastGetVars,
      ...sowPigUpdateVars,
      forecasts: [],
      populatingInputs: false,
      ...createSowPigInputs(),
    }
  },
  computed: {
    stockCategoryId () {
      return this.stockCategory.id || null
    },
    editMode () {
      return Boolean(this.$route.params.customerForecastId)
    },
    sowPigErrors () {
      const postErrors = this.parseCustomerForecastErrors(this.cxForecastPostErrors)
      return { ...postErrors, ...this.sowPigPutErrors }
    },
  },
  watch: {
    stockCategoryId () {
      this.fetchForecastData()
      this.setQuantity()
    },
    days (val) {
      if (this.populatingInputs) return
      if (!val) {
        this.fdPrgmCategoryList = []
        return
      }
      this.fetchForecastData()
      this.generateForecasts()
    },
    dateConfirmed (val) {
      if (this.populatingInputs) return
      this.generateForecasts()
    },
    dateStart () {
      if (this.populatingInputs) return
      this.generateForecasts()
    },
    fdPrgmCategoryList () {
      if (this.populatingInputs) return
      this.generateForecasts()
    },
    defaultFieldValues (item) {
      this.populateInputs(item)
    },
    sowPigData (data, lastData) {
      if (!this.verifySowPigData(data, lastData)) return
      this.sowPigDataStatusUpdate(data)
    },
  },
  methods: {
    parseCustomerForecastErrors (errors) {
      const regex = /sow_pig./g
      return Object.keys(errors)
        .filter(key => regex.test(key))
        .reduce((result, key) => {
          result[key.replace('sow_pig.')] = errors[key]
        }, {})
    },
    initializeSowPigData () {
      const { customerId } = this.$route.params
      this.sowPigData = { customerId }
    },
    sendRequestToCustomerForecast () {
      if (this.editMode) {
        this.$confirm('Are you sure you want to overwrite this Forecast  ').then(this.updateCustomerForecast)
        return
      }
      this.storeCustomerForecast()
    },
    setQuantity () {
      const { stockCategory } = this
      if (!stockCategory.sow_pig_cycle_preference) return
      this.quantity = 1
    },
    modifyTypeAttributes (items) {
      return items.map(item => this.converTypeToFeedingProgramCategory(item))
    },
    getDefaultFieldValues () {
      const { customerForecastId } = this.$route.params
      if (!customerForecastId) return
      const handler = customerForecastGetRequestHandler
      handler.setVue(this)
      const repository = customerForecastRespository.edit
      const params = getDefaultValueParams()
      handler.execute(repository, [customerForecastId, params])
    },
    updateCustomerForecast (confirm) {
      if (!confirm) return
      const handler = customerForecastUploadRequestHandler
      handler.setVue(this)
      const repository = customerForecastRespository.update
      const { data, id } = this.getInputData()
      handler.execute(repository, [id, data])
    },
    storeCustomerForecast () {
      const handler = customerForecastUploadRequestHandler
      handler.setVue(this)
      const repository = customerForecastRespository.store
      const { data } = this.getInputData()
      handler.execute(repository, [data])
    },
    getFeedingProgramCategories () {
      this.cxForecastPostErrors = {}
      const handler = feedingProgramCategoriesGetRequestHandler
      handler.setVue(this)
      const { stockCategoryId, days } = this
      const params = { days, purpose: 'forecasts' }
      const repository = feedingProgramCategoryRepository.stockCategory
      handler.execute(repository, [stockCategoryId, params])
    },
    getStockCategories () {
      const handler = stockCategoriesGetRequestHandler
      handler.setVue(this)
      const repository = stockCategoryRepository.index
      const params = this.getStockCategoriesParams()
      handler.execute(repository, [params])
    },
    getStockCategoriesParams () {
      return {
        has: ['feedingProgramCategory'].join(','),
        with: ['sowPigCyclePreference'].join(','),
      }
    },
    getInputData () {
      const { customerId } = this.$route.params
      const data = objectChangeKeyCase({
        ...this.getForecastInputs(),
        customerId,
        types: this.getForecastTypeInputs(),
        sowPig: this.sowPigData,
      }, 'camelToSnakeCase')
      const id = this.$route.params.customerForecastId || null
      return { data, id }
    },
    getForecastInputs () {
      const excluded = ['stockCategory']
      const included = ['stockCategoryId']
      return included.concat(Object.keys(inputVars()))
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
    getForecastTypeInputs () {
      return this.forecasts.map(item => {
        const attributes = ['type', 'stockId', 'dateStart', 'dateEnd']
        return attributes.reduce((result, key) => {
          result[key.camelToSnakeCase()] = item[key]
          return result
        }, {})
      })
    },
    generateForecasts () {
      const { defaultFieldValues } = this
      const actualDays = computeDaysAfterConfirmedAndStart(this.days, this.dateConfirmed, this.dateStart)
      this.forecasts = this.fdPrgmCategoryList.map(modifyFeedingProgramAttributes(actualDays, this.dateStart))
        .filter(item => item.feedingPrograms.length)
    },
    mapForecastTypes () {
      const { forecasts } = this
      return forecasts.map(e => ({
        stock_id: e.stockId,
        type: e.type,
        date_start: e.dateStart,
        date_end: e.dateEnd,
      }))
    },
    websocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbUpdateEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'SowPig') {
        this.sowPigDBUpdateEvent(data)
      }
    },
    sowPigDBUpdateEvent (data) {
      this.sowPigData = data
      lastSaveSowPigData = data
    },
    fetchForecastData () {
      const { days = null, stockCategoryId = null } = this
      if (!stockCategoryId) return
      this.getFeedingProgramCategories()
    },
    converTypeToFeedingProgramCategory (item) {
      const excluded = ['created_at', 'updated_at']
      return Object.keys(item)
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          const key2 = key.snakeToCamelCase()
          if (key2 === 'feedingProgramCategory') {
            result = { ...result, ...this.$objectChangeKeyCase(item[key]) }
            return result
          }
          if (isNaN(item[key])) {
            result[key2] = item[key]
            return result
          }
          result[key2] = Number(item[key])
          return result
        }, {})
    },
    assignEachDefaultValueToAnInput (item) {
      Object.keys(item).forEach(key => {
        if (key === 'types') {
          this.forecasts = this.modifyTypeAttributes(item.types)
          return
        }
        if (key === 'sow_pig') {
          this.sowPigData = objectChangeKeyCase(item.sow_pig || {})
          lastSaveSowPigData = this.sowPigData
        }
        this[key.snakeToCamelCase()] = item[key]
      })
    },
    populateInputs (item) {
      this.populatingInputs = true
      this.assignEachDefaultValueToAnInput(item)
      this.stopPopulatingInputs()
    },
    stopPopulatingInputs () {
      populatingInputs(() => {
        this.$nextTick(() => { this.populatingInputs = false })
      })
    },
    feedingProgramChangeEvent (item, search) {
      const { dateConfirmed = getCurrentDate(), dateStart = getCurrentDate(), days = 0 } = this
      const actualDays = computeDaysAfterConfirmedAndStart(days, dateConfirmed, dateStart)
      this.forecasts = this.forecasts.map((subItem, index) => {
        if (index === search) {
          const result = modifyFeedingProgramAttributes(actualDays, dateStart)(item)
          return Object.assign({ ...result })
        }
        return Object.assign({ ...subItem })
      })
    },
    openDialogAttachSowPig () {
      const { customerId } = this.$route.params
      if (!this.editMode) this.sowPigData = { customerId }
      this.sowPigDialog = true
    },
    sendRequestSowPigAndForecast () {
      if (this.noChangesSowPigData()) return
      this.$confirm('Are you sure you want to save changes?')
        .then(confirm => {
          this.updateSowPig(confirm)
          this.updateCustomerForecast(confirm)
        })
    },
    updateSowPig (confirm) {
      if (!confirm) return
      const handler = sowPigUpdateRequestHandler
      const repository = sowPigRepository.update
      const { id, data } = this.sowPigUpdateInputs()
      handler.setVue(this)
      handler.execute(repository, [id, data])
    },
    sowPigUpdateInputs () {
      const excluded = ['customerForecastId', 'customerId', 'id']
      const { sowPigData } = this
      const { id } = sowPigData
      const data = Object.keys(sowPigData)
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          const key2 = key.camelToSnakeCase()
          result[key2] = sowPigData[key]
          return result
        }, {})
      return { id, data }
    },
    revertSowPig () {
      if (!this.editMode) return
      this.sowPigData = lastSaveSowPigData
    },
    noChangesSowPigData () {
      const { sowPigData } = this
      return Object.keys(sowPigData).every(key => sowPigData[key] === lastSaveSowPigData[key])
    },
    sowPigDataStatusUpdate (data) {
      const { service = null, farrowing = null, weaning = null } = data || {}
      if (service && farrowing) {
        this.lactationStage()
        return
      }
      if (service) this.defaultStage()
    },
    defaultStage () {
      const { service = null } = this.sowPigData || {}
      const { sowPigCyclePreference } = objectChangeKeyCase(this.stockCategory) || {}
      const serviceEnd = service.addDays(sowPigCyclePreference.service)
      this.forecasts.first().dateStart = service
      this.forecasts.first().dateEnd = serviceEnd
      this.forecasts.last().dateStart = serviceEnd.addDays(1)
      this.forecasts.last().dateEnd = serviceEnd.addDays(1 + sowPigCyclePreference.farrowing + sowPigCyclePreference.weaning)
    },
    lactationStage () {
      const { service = null, farrowing = null } = this.sowPigData || {}
      this.forecasts.first().dateStart = service
      this.forecasts.first().dateEnd = farrowing.subDays(1)
      this.forecasts.last().dateStart = farrowing
      this.forecasts.last().dateEnd = this.getLactationLength()
    },
    getLactationLength () {
      const { subtractDates } = Window
      const { sowPigCyclePreference } = objectChangeKeyCase(this.stockCategory) || {}
      const { farrowing = null, weaning = null } = this.sowPigData || {}
      if (!weaning) {
        return farrowing.addDays(sowPigCyclePreference.farrowing + sowPigCyclePreference.weaning)
      }
      const farrowingLength = subtractDates(weaning, farrowing)
      return farrowing.addDays(farrowingLength + sowPigCyclePreference.weaning)
    },
    verifySowPigData (data, lastData) {
      const excluded = ['sowNumber', 'piglets']
      if (data.service < this.dateStart) {
        this.$nextTick(() => { this.sowPigData.service = lastData.service })
        return false
      }
      return !Object.keys(data)
        .filter(key => !excluded.includes(key))
        .every(key => data[key] === lastData[key])
    },
  },
}
</script>
