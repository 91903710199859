import repository from './repository'

const resource = 'sow-pigs'

export default {
  customer (customerId) {
    return repository.get(`${resource}/${customerId}/customer`)
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
}
